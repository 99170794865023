<template>
  <div>
    <ui-button
      @click.native="
        dialog = true;
        refresh();
      "
      >View Details</ui-button
    >
    <ui-modal v-if="dialog" @close="dialog = false">
      <div class="sm:tw-flex sm:tw-items-center">
        <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left">
          <h3 as="h3" class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">DeFi Position Details</h3>
        </div>
      </div>
      <div class="tw-hidden sm:tw-block tw-pt-3" style="width: 1000px; min-height: 300px">
        <nav class="tw-flex tw-space-x-4 tw-items-baseline tw-pb-3" aria-label="Tabs">
          <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->

          <a :class="getTabClass(0)" @click="selectedTab = 0">Vault Details</a>

          <a :class="getTabClass(1)" @click="selectedTab = 1">Configuration</a>

          <a
            v-if="this.isLiquidityPool"
            :class="getTabClass(2)"
            @click="
              selectedTab = 2;
              drawChart();
            "
            >Liquidity Analysis</a
          >
        </nav>
        <div v-if="selectedTab === 0">
          <div>
            <span> <b>Platform:</b> {{ this.addressDetails ? this.addressDetails.friendlyName : '' }} </span>
            <br />
            <span>
              <b> Vault Address: </b>
              <td style="text-overflow: ellipsis; overflow: hidden; width: 100%; max-width: 500px">
                {{ this.vaultAddress }}
              </td>
            </span>
            <br />
            <span v-if="this.addressDetails && this.addressDetails.token && this.addressDetails.token.name">
              <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                <b> Name: </b>
                {{ this.addressDetails.token.name }}
              </td>
            </span>
            <br />
            <span v-if="this.addressDetails && this.addressDetails.token && this.addressDetails.token.website">
              <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                <b> Website: </b>
                {{ this.addressDetails.token.website }}
              </td>
            </span>
            <br />
            <span v-if="this.addressDetails && this.addressDetails.token && this.addressDetails.token.description">
              <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                <b> Description: </b>
                {{ this.addressDetails.token.description }}
              </td>
            </span>
          </div>
          <div class="tw-border tw-p-1 tw-rounded-md">
            <v-data-table
              :headers="assetHeaders"
              :items="vaultAssets"
              :key="bumpKey"
              :pagination.sync="pagination"
              item-key="name"
              :hide-actions="!vaultAssets || vaultAssets.length <= 5"
              no-data-text=""
              class=""
              :rows-per-page-items="[10]"
              :loading="isLoading ? 'accent' : false"
            >
              <template slot="items" slot-scope="props">
                <tr style="height: 60px">
                  <td class="text-xs-left">
                    <b>{{ props.item.name }}</b>
                  </td>
                  <td
                    class="text-xs-left"
                    style="text-overflow: ellipsis; overflow: hidden; width: 100%; max-width: 200px"
                  >
                    <span>
                      <b>{{ props.item.assetAddress }}</b>
                    </span>
                    <v-icon
                      size="10"
                      style="cursor: pointer; padding: 0 10px 0 5px"
                      v-clipboard:copy="props.item.assetAddress"
                    >
                      content_copy
                    </v-icon>
                  </td>
                  <td class="text-xs-right">
                    <b>{{ abbreviate(props.item.assetBalance, 7, false) }}</b>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <div v-if="selectedTab === 1">
          <v-layout row wrap class="pa-3" :key="bumpKey" v-if="this.wallet">
            <v-flex xs12>
              <form>
                <v-text-field @change="configurationChanged = true" v-model="name" label="Name"></v-text-field>
                <v-text-field
                  @change="configurationChanged = true"
                  v-model="description"
                  label="Description"
                ></v-text-field>
                <v-text-field v-model="walletId" label="walletID" disabled></v-text-field>
                <v-text-field v-model="vaultAddress" label="Vault Address" disabled></v-text-field>
                <v-text-field v-model="walletAddress" label="Wallet Address" disabled></v-text-field>
                <v-layout row wrap>
                  <v-flex xs3>
                    <v-checkbox
                      @change="configurationChanged = true"
                      v-model="syncingEnabled"
                      label="Syncing Enabled"
                      required
                    ></v-checkbox>
                  </v-flex>
                  <!-- Commenting this for now as we just want to stop syncing 0 value in the defi-svc -->
                  <!-- <v-flex xs3 v-if="syncingEnabled">
                      <v-checkbox
                        @change="configurationChanged = true"
                        v-model="syncToLatestInteraction"
                        label="Only Sync to Latest Vault Interaction"
                        required
                      ></v-checkbox>
                    </v-flex> -->
                  <v-flex xs3 v-if="syncingEnabled">
                    <v-checkbox
                      @change="configurationChanged = true"
                      v-model="categorizeTransactions"
                      label="Automatically Categorize Interactions as DeFi"
                      required
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs3 v-if="syncingEnabled">
                    <v-checkbox
                      @change="configurationChanged = true"
                      v-model="generateAccruals"
                      label="Generate Periodic Rebalancing Transactions"
                      required
                    >
                    </v-checkbox>
                  </v-flex>
                  <v-flex xs3 v-if="syncingEnabled && generateAccruals">
                    <v-checkbox
                      @change="configurationChanged = true"
                      v-model="categorizeAccruals"
                      label="Automatically Categorize Rebalancing Transactions"
                      required
                    >
                    </v-checkbox>
                  </v-flex>
                  <v-flex xs4 class="pa-2" v-if="generateAccruals && categorizeAccruals && syncingEnabled">
                    <v-autocomplete
                      @change="configurationChanged = true"
                      v-model="defaultAccrualContact"
                      :items="contacts"
                      :label="`Default Contact for Rebalancing Transactions`"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs4 class="pa-2" v-if="generateAccruals && categorizeAccruals && syncingEnabled">
                    <v-autocomplete
                      @change="configurationChanged = true"
                      :items="categories"
                      :label="`Default Category for Rebalancing Transactions`"
                      v-model="defaultAccrualCategory"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                    >
                      <template slot="item" slot-scope="data">
                        <template>
                          <v-list-tile-content
                            >{{ data.item.name }} <sub>{{ data.item.type }}</sub></v-list-tile-content
                          >
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      @change="configurationChanged = true"
                      v-if="syncingEnabled && generateAccruals"
                      v-model="accrualCadence"
                      :items="intervals.map((i) => i.toLocaleString())"
                      label="Rebalancing Transaction Interval"
                      required
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-btn
                  v-if="this.selectedWallet"
                  @click="this.save"
                  :disabled="this.saveDisabled || !this.configurationChanged"
                  >save</v-btn
                >
                <v-btn @click="this.rerunSync" color="red">Rerun rebalancing & categorization</v-btn>
                <v-progress-linear indeterminate color="green" v-if="saveLoading"></v-progress-linear>
              </form>
            </v-flex>
          </v-layout>
          <div class="tw-flex tw-p-3" v-else>
            <span> Please Create A Wallet To Use This Feature </span>
          </div>
        </div>
        <div v-if="selectedTab === 2">
          <v-flex xs12 class="justify-center" primary-title>
            <v-progress-linear indeterminate color="green" v-if="isLoading"></v-progress-linear>
            <div class="tw-font-medium d-flex justify-center mb-6 text-h4 pa-2" primary-title style=".display-3">
              Current Position Composition
            </div>
          </v-flex>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <div class="mx-auto tw-border tw-rounded-md tw-p-2">
                <chart :data="chartX"></chart>
              </div>
            </v-flex>
            <v-flex xs12 md6>
              <div class="mx-auto tw-border tw-rounded-md tw-p-2">
                <chart :data="chartY"></chart>
              </div>
            </v-flex>
            <v-flex xs6>
              <div class="mx-auto tw-border tw-rounded-md tw-p-2 tw-h-full" :key="1">
                <v-card-text>
                  <span>
                    <b>Platform:</b> {{ this.addressDetails ? this.addressDetails.friendlyName : '' }}
                    <br />
                  </span>

                  <span>
                    <b> Vault Address: </b>
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%; max-width: 500px">
                      {{ this.vaultAddress }}
                    </td>
                    <br />
                  </span>

                  <span v-if="this.addressDetails && this.addressDetails.token && this.addressDetails.token.name">
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                      <b> Name: </b>
                      {{ this.addressDetails.token.name }}
                    </td>
                    <br />
                  </span>

                  <span v-if="this.addressDetails && this.addressDetails.token && this.addressDetails.token.website">
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                      <b> Website: </b>
                      {{ this.addressDetails.token.website }}
                    </td>
                    <br />
                  </span>

                  <span
                    v-if="this.addressDetails && this.addressDetails.token && this.addressDetails.token.description"
                  >
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                      <b> Description: </b>
                      {{ this.addressDetails.token.description }}
                    </td>
                    <br />
                  </span>

                  <span>
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                      <b>Implied Current Price: </b>
                      {{ this.impliedPriceText }}
                    </td>
                  </span>
                </v-card-text>
              </div>
            </v-flex>
            <v-flex xs6>
              <div class="mx-auto tw-border tw-rounded-md tw-p-2" :key="2">
                <chart :key="simulationBumpKey" height="140px" :data="simulationChart.chartData"></chart>
                <v-flex xs12 class="justify-center" primary-title>
                  <div class="d-flex justify-center mb-6 text-h4 pa-2" primary-title style=".display-3">
                    Impermanent Loss Simulation
                  </div>
                </v-flex>
              </div>
            </v-flex>

            <!-- <v-flex>
                <v-container v-bind="{ [`grid-list-lg`]: true }" fluid :key="bumpKey">
                  
                </v-container>
              </v-flex> -->
          </v-layout>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import * as axios from 'axios';
import gql from 'graphql-tag';

import { baConfig } from '../../../config';
import Chart from '../Chart.vue';
import UiButton from '../ui/UiButton.vue';
import UiModal from '../ui/UiModal.vue';
export default {
  props: ['networkId', 'walletAddress', 'vaultAddress', 'tokenId', 'walletId', 'walletName', 'wallet'],
  data() {
    return {
      dialog: false,
      chartX: null,
      chartY: null,
      simulationChart: {
        selectedPrice: 0,
        chartData: null,
      },
      selectedTab: 0,
      addressDetails: null,
      vaultBalance: null,
      vaultRevenue: null,
      assetHeaders: [
        {
          text: 'Asset Ticker',
          align: 'left',
          value: 'name',
          width: '50px',
        },
        {
          text: 'Asset Address',
          align: 'left',
          value: 'assetAddress',
          width: '50px',
        },
        {
          text: 'Balance',
          align: 'right',
          value: 'assetBalance',
          width: '100px',
        },
      ],
      bumpKey: 0,
      simulationBumpKey: 0,
      pagination: null,
      isLoading: false,
      saveLoading: false,
      configurationChanged: false,
      intervals: ['hourly', 'daily', 'weekly', 'monthly', 'yearly'],
      defaultAccrualContact: null,
      defaultAccrualCategory: null,
    };
  },
  computed: {
    selectedWallet() {
      const wal = this.wallet;
      return wal;
    },
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
    categories() {
      return this.$store.getters['categories/ENABLE_CATEGORIES'];
    },
    saveDisabled() {
      if (this.name !== '') {
        if (this.syncingEnabled) {
          if (this.generateAccruals) {
            if (this.accrualCadence) {
              if (this.categorizeAccruals) {
                if (this.defaultAccrualContact && this.defaultAccrualCategory) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isLiquidityPool() {
      return this.addressDetails && this.addressDetails.type === 'token-liquidity-pool';
    },
    vaultAssets() {
      if (this.vaultBalance && this.addressDetails?.assets) {
        const ret = this.vaultBalance.map((m) => {
          return {
            name: m.ticker,
            assetAddress: m.assetAddress,
            assetBalance: m.value,
          };
        });
        return ret;
      } else {
        return [];
      }
    },
    impliedPrice() {
      if (this.isLiquidityPool && this.vaultBalance) {
        // x/y
        return Number(this.vaultBalance[0].value) / Number(this.vaultBalance[1].value);
      } else {
        return 0;
      }
    },
    impliedPriceText() {
      if (this.isLiquidityPool && this.vaultBalance) {
        const priceXPerY = Number(this.vaultBalance[0].value) / Number(this.vaultBalance[1].value);
        const priceYPerX = 1 / priceXPerY;
        const xTicker = this.vaultBalance[0].ticker;
        const yTicker = this.vaultBalance[1].ticker;
        return `${priceXPerY} ${xTicker} per ${yTicker}, or ${priceYPerX} ${yTicker} per ${xTicker}`;
      } else {
        return 'N/A';
      }
    },
    simulatedPrice() {
      return this.scale(this.simulationChart.selectedPrice, this.impliedPrice / 10, this.impliedPrice * 10);
    },
    simulatedPriceText() {
      if (this.isLiquidityPool && this.vaultBalance) {
        const priceXPerY = this.scale(
          this.simulationChart.selectedPrice,
          this.impliedPrice / 10,
          this.impliedPrice * 10
        );
        const priceYPerX = 1 / priceXPerY;
        const xTicker = this.vaultBalance[0].ticker;
        const yTicker = this.vaultBalance[1].ticker;
        return `${this.abbreviate(priceXPerY, 5)} ${xTicker} per ${yTicker}, or ${this.abbreviate(
          priceYPerX,
          5
        )} ${yTicker} per ${xTicker}`;
      } else {
        return 'N/A';
      }
    },
    syncingEnabled: {
      get() {
        return this.wallet?.isSyncEnabled;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.isSyncEnabled = value;
        }
      },
    },
    categorizeTransactions: {
      get() {
        return this.wallet?.categorizeTransactions;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.categorizeTransactions = value;
        }
      },
    },
    syncToLatestInteraction: {
      get() {
        return this.wallet?.syncToLatestInteraction;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.syncToLatestInteraction = value;
        }
      },
    },
    generateAccruals: {
      get() {
        return this.wallet?.generateAccruals;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.generateAccruals = value;
        }
      },
    },
    categorizeAccruals: {
      get() {
        return this.wallet?.categorizeAccruals;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.categorizeAccruals = value;
        }
      },
    },
    accrualCadence: {
      get() {
        return this.wallet?.accrualCadence;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.accrualCadence = value;
        }
      },
    },
    name: {
      get() {
        return this.wallet?.name;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.name = value;
        }
      },
    },
    description: {
      get() {
        return this.wallet?.description;
      },
      set(value) {
        if (this.wallet) {
          this.wallet.description = value;
        }
      },
    },
  },
  mounted() {
    this.name = this.walletName;
    if (this.selectedWallet) {
      this.defaultAccrualContact = this.contacts.find((f) => f.id === this.selectedWallet.defaultAccrualContact);
      this.defaultAccrualCategory = this.categories.find((f) => f.id === this.selectedWallet.defaultAccrualCategory);
    }
    this.bumpKey++;
  },
  methods: {
    getTabClass(id) {
      if (id === this.selectedTab) {
        return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
      } else {
        return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
      }
    },
    async save() {
      if (this.wallet) {
        this.saveLoading = true;
        const orgId = this.$store.state.currentOrg.id;
        const walletId = this.selectedWallet.id;
        const name = this.name;
        const description = this.description;
        const variables = {
          orgId,
          walletId,
          name,
          description,
          updateDeFiWallet: {
            isSyncEnabled: this.syncingEnabled,
            categorizeTransactions: this.categorizeTransactions,
            accrualCadence: this.accrualCadence,
            syncToLatestInteraction: this.syncToLatestInteraction,
            generateAccruals: this.generateAccruals,
            categorizeAccruals: this.categorizeAccruals,
            defaultAccrualContact: this.defaultAccrualContact ? this.defaultAccrualContact.id : undefined,
            defaultAccrualCategory: this.defaultAccrualCategory ? this.defaultAccrualCategory.id : undefined,
          },
          addWallets: [],
          removeWallets: [],
        };

        try {
          await this.$apollo.mutate({
            mutation: gql`
              mutation (
                $orgId: ID!
                $walletId: ID!
                $name: String
                $description: String
                $addWallets: [String]
                $removeWallets: [String]
                $updateDeFiWallet: DeFiWalletUpdateInput
              ) {
                updateWallet(
                  orgId: $orgId
                  walletId: $walletId
                  name: $name
                  description: $description
                  addWallets: $addWallets
                  removeWallets: $removeWallets
                  updateDeFiWallet: $updateDeFiWallet
                ) {
                  id
                }
              }
            `,
            variables,
          });
        } catch (e) {
          console.log(e);
          this.saveLoading = false;
        } finally {
          this.$root.$emit('refresh-wallets');
          this.configurationChanged = false;
          this.saveLoading = false;
        }
      }
    },
    async rerunSync() {
      if (this.wallet) {
        const orgId = this.$store.state.currentOrg.id;
        const walletId = this.selectedWallet.id;
        const tokenId = walletId.split('.')[3] ? '' : '|undefined';
        const urlSuffix = `/jobs/defi-full-sync-workflow/${orgId}|${walletId}${tokenId}/start?clearSyncState=true`;
        const baseUrl = baConfig.defiSvcUrl;
        const useUrl = baseUrl.concat(urlSuffix);
        await axios.post(useUrl);
      }
    },
    async drawChart() {
      if (!this.vaultRevenue) {
        this.isLoading = true;
        await this.getVaultRevenue();
        this.isLoading = false;
      }
      [this.chartX, this.chartY] =
        this.vaultRevenue?.map((m) => {
          return {
            type: 'bar',
            data: {
              labels: [`${m.ticker} Principal`, `${m.ticker} Revenue`],
              datasets: [
                {
                  label: `${m.ticker}`,
                  data: [Number(m.principal), Number(m.revenue)],
                  borderWidth: 1,
                  backgroundColor: ['rgb(104, 200, 35)', 'rgb(255, 9, 32)'],
                },
              ],
            },
          };
        }) ?? [];

      this.bumpKey++;
    },
    updateSimulationChart() {
      const currentK = Number(this.vaultBalance[0].value) * Number(this.vaultBalance[1].value);
      const priceArray = [];
      const textPriceArray = [];
      for (let i = 0; i <= 100; i++) {
        this.simulationChart.selectedPrice = i;
        priceArray.push(this.simulatedPrice);
        textPriceArray.push(this.simulatedPriceText);
      }

      const xValueArray = [];
      const yValueArray = [];

      for (const price of priceArray) {
        const yGivenP = Math.sqrt(currentK / price);
        const xGivenY = currentK / yGivenP;
        xValueArray.push(xGivenY);
        yValueArray.push(yGivenP);
      }

      this.simulationChart.chartData = {
        type: 'line',
        data: {
          labels: textPriceArray,
          datasets: [
            {
              yAxisID: 'yassetX',
              label: this.vaultBalance[0].ticker,
              data: xValueArray,
              borderWidth: 1,
              backgroundColor: ['rgb(54, 162, 235)'],
            },
            {
              yAxisID: 'yassetY',
              label: this.vaultBalance[1].ticker,
              data: yValueArray,
              borderWidth: 1,
              backgroundColor: ['rgb(255, 99, 132)'],
            },
            {
              type: 'bar',
              label: 'Current Price',
              legend: { hidden: true },
              backgroundColor: ['#ff0000'],
              data: [
                {
                  x: textPriceArray[50],
                  y: xValueArray[50],
                },
              ],
            },
          ],
        },
        options: {
          scales: {
            yassetX: {},
            yassetY: {},
            xAxes: {
              ticks: {
                // callback: () => (''),
                display: false,
              },
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
        },
      };
      this.simulationBumpKey++;
    },
    async refresh() {
      this.isLoading = true;
      const a = this.getAddressDetails();
      const b = this.getVaultBalance();
      await Promise.all([a, b]);
      this.isLoading = false;
      if (this.isLiquidityPool) {
        this.updateSimulationChart();
        await this.getVaultRevenue();
      }
    },
    async getAddressDetails() {
      const addressDetailsUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${this.vaultAddress}`;
      const resp = await axios.get(addressDetailsUrl);
      if (resp && resp.status === 200) {
        this.addressDetails = resp.data.item;
        this.bumpKey++;
      } else {
      }
    },
    async getVaultBalance() {
      const vaultBalanceUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${this.vaultAddress}/vaultBalance?ownerAddress=${this.walletAddress}&includeStakedHoldings=true`;
      const resp = await axios.get(vaultBalanceUrl, {
        validateStatus: () => true,
      });
      if (resp && resp.status === 200) {
        this.vaultBalance = resp.data.balance;
        this.bumpKey++;
      } else {
      }
    },
    async getVaultRevenue() {
      const vaultRevenueUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${this.vaultAddress}/vaultRevenue?ownerAddress=${this.walletAddress}`;
      const resp = await axios.get(vaultRevenueUrl, {
        validateStatus: () => true,
      });
      if (resp && resp.status === 200) {
        this.vaultRevenue = resp.data.balance;
        this.bumpKey++;
      } else {
      }
    },
    scale(position, min, max) {
      const minPosition = 0;
      const maxPosition = 100;

      const logMin = Math.log(min);
      const logMax = Math.log(max);

      const scale = (logMax - logMin) / (maxPosition - minPosition);

      return Math.exp(logMin + scale * (position - minPosition));
    },
    abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
      number = Number(number);
      forceLetter = forceLetter || false;
      if (forceLetter !== false) {
        return this.annotate(number, maxPlaces, forcePlaces, forceLetter);
      }
      var abbr;
      if (number >= 1e12) {
        abbr = 'T';
      } else if (number >= 1e9) {
        abbr = 'B';
      } else if (number >= 1e6) {
        abbr = 'M';
      } else if (number >= 1e3) {
        abbr = 'K';
      } else {
        abbr = '';
      }
      return this.annotate(number, maxPlaces, forcePlaces, abbr);
    },
    annotate(number, maxPlaces, forcePlaces, abbr) {
      var rounded = 0;
      switch (abbr) {
        case 'T':
          rounded = number / 1e12;
          break;
        case 'B':
          rounded = number / 1e9;
          break;
        case 'M':
          rounded = number / 1e6;
          break;
        case 'K':
          rounded = number / 1e3;
          break;
        case '':
          rounded = number;
          break;
      }
      if (maxPlaces) {
        var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
        if (test.test('' + rounded)) {
          rounded = rounded.toFixed(maxPlaces);
        }
      }
      if (forcePlaces) {
        rounded = Number(rounded).toFixed(forcePlaces);
      }
      return rounded + abbr;
    },
  },
  components: {
    Chart,
    UiModal,
    UiButton,
  },
};
</script>
