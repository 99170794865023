<template>
  <div class="tw-font-sans">
    <div class="tw-border-b tw-border-gray-300 tw-border-t tw-flex tw-pr-3 tw-items-center">
      <h3
        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 tw-ml-3 tw-py-2 tw-font-heading tw-inline-flex tw-items-center"
      >
        DeFi <beta />
      </h3>
      <div class="sm:tw-ml-10">
        <nav class="tw--mb-px tw-flex tw-space-x-8">
          <a
            :class="getTabClass(iv.id)"
            aria-current="page"
            v-for="iv in [
              { id: 0, name: 'My Wallets' },
              { id: 1, name: 'Classified Positions' },
            ]"
            :key="iv.id"
            @click="selectedTab = iv.id"
            >{{ iv.name }}</a
          >
        </nav>
      </div>
      <div class="tw-flex tw-flex-grow tw-justify-end">
        <ba-dialog
          :show="dialog"
          btnColor="success"
          :btnName="'Create New DeFi Wallet'"
          :btnTooltip="'Create New DeFi Wallet'"
          :title="'Create New DeFi Wallet'"
          :onHide="hideDialog"
          v-if="checkScope(scopeLiterals.WalletsCreate)"
        >
          <defi-wallet
            v-on:wallet-saved="walletSaved"
            :details="this.defiDetails"
            :key="this.bumpKey"
            wallet-type="defi"
          />
        </ba-dialog>
      </div>
    </div>

    <div v-if="this.selectedTab === 0" class="tw-rounded-t-md tw-bg-white">
      <div class="tw-flex tw-rounded-t-md tw-p-2 tw-border-b">
        <ui-select2
          v-if="displayWallets && displayWallets.length > 0"
          :items="displayWallets"
          labelField="name"
          searchable
          :value="viewWallet"
          @input="setActiveWallet"
          fontSize="base"
          max-scroll-height="200px"
          label="Wallets"
          :clearable="true"
        ></ui-select2>
        <div class="tw-flex tw-flex-grow tw-justify-end">
          <div class="tw-flex tw-h-12 tw-w-hull tw-my-2.5">
            <div class="tw-flex tw-rounded tw-p-2 tw-border tw-justify-end">
              <input
                placeholder="Search DeFi Positions"
                class="tw-flex-1 tw-outline-none tw-focus:outline-none"
                v-model="searchKeyword"
              />
              <i class="material-icons tw-text-gray-500">search</i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.viewWallet" class="tw-flex-grow">
        <v-progress-linear indeterminate color="green" v-if="isLoading"></v-progress-linear>
        <div class="tw-border-gray-300 tw-flex tw-p-3 tw-items-center tw-gap-4">
          <h3
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 tw-ml-3 tw-py-2 tw-font-heading tw-inline-flex tw-items-center"
          >
            {{ this.viewWallet.name }}
          </h3>
          <span class="subheading"
            >Last Updated:
            <span class="tw-font-medium">{{
              this.walletSearchTokens ? this.getFriendlyDate(this.walletSearchTokens.timestampSeconds) : 'Never'
            }}</span></span
          >
          <div class="tw-flex-grow"></div>
          <ui-button color="white" @click.native="updateDeFiWallet()">Recheck</ui-button>
        </div>
        <v-container v-bind="{ [`grid-list-lg`]: true }" fluid>
          <v-layout row wrap>
            <v-flex v-for="(token, i) in this.displayPositions" :key="i" xs6>
              <v-card flat tile height="170px" class="tw-rounded tw-shadow mx-auto" :key="bumpKey">
                <v-card-text>
                  <span>
                    Platform ID: <b> {{ token.addressDetails_platformId.toUpperCase() }} </b>
                  </span>
                  <br />
                  <span>
                    Address:
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%; max-width: 200px">
                      <b> {{ token.address }} </b>
                    </td>
                  </span>
                  <v-icon size="15" style="cursor: pointer" v-clipboard:copy="token.address"> content_copy </v-icon>
                  <br />
                  <span>
                    Symbol:
                    <td style="text-overflow: ellipsis; overflow: hidden; width: 100%">
                      <b> {{ token.addressDetails_contractSymbol }} </b>
                    </td>
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-list-tile class="grow">
                    <v-layout align-center justify-end class="tw-gap-2">
                      <ui-button
                        color="white"
                        v-if="
                          !defiWalletExists(
                            getPositionIdentifier(token.addressDetails_networkId, viewWalletAddress, token.address)
                          )
                        "
                        @click.native="
                          createDefiWalletModal(token.addressDetails_networkId, viewWalletAddress, token.address)
                        "
                      >
                        Create Wallet
                      </ui-button>
                      <v-chip disabled class="green" text-color="white" v-else> Wallet Added </v-chip>
                      <defi-position-preview
                        :networkId="token.addressDetails_networkId"
                        :walletAddress="viewWalletAddress"
                        :vaultAddress="token.address"
                        :walletId="
                          getPositionIdentifier(token.addressDetails_networkId, viewWalletAddress, token.address)
                        "
                        :walletName="
                          defiWalletName(
                            getPositionIdentifier(token.addressDetails_networkId, viewWalletAddress, token.address)
                          )
                        "
                        :wallet="
                          defiWallet(
                            getPositionIdentifier(token.addressDetails_networkId, viewWalletAddress, token.address)
                          )
                        "
                      >
                      </defi-position-preview>
                    </v-layout>
                  </v-list-tile>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="text-xs-center align-end justify-center row fill-height">
          <v-pagination
            v-if="this.displayPositions && this.displayPositions.length"
            class="align-end"
            v-model="walletPositionsPage"
            :length="walletPositionPageCount"
          >
          </v-pagination>
          <div v-else>
            <span>No Positions for This Wallet</span>
          </div>
        </div>
      </div>
      <div v-else class="tw-p-6 tw-flex tw-justify-center tw-w-full">
        <span>Please Select a Wallet</span>
      </div>
    </div>
    <v-flex v-if="this.selectedTab === 1">
      <v-data-table
        :headers="verboseWalletHeaders"
        :items="existingDeFiWallets"
        :pagination.sync="pagination"
        item-key="id"
        :hide-actions="!wallets || wallets.length <= 5"
        :loading="isLoading ? 'accent' : false"
        no-data-text=""
        class="tw-border-b"
        :rows-per-page-items="[10]"
      >
        <template slot="verboseWalletHeaders" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column text-capitalize sortable text-xs-' + header.align,
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
              :width="header.width"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr style="height: 60px; cursor: pointer" @click="showWalletDetails(props.item)">
            <td class="text-xs-left" style="min-width: 350px">
              <b>{{ props.item.name }}</b>
            </td>
            <td class="text-xs-left">
              <b>{{ props.item.address.split('.')[0] }}</b>
            </td>
            <td class="text-xs-left">
              <b>{{ props.item.address.split('.')[1] }}</b>
            </td>
            <td>
              <div>
                <div v-if="isLoadingBalance">
                  <div class="tw-animate-pulse tw-w-3/4 tw-h-5 tw-bg-gray-300"></div>
                </div>
                <table style="width: 100%" class="align-right" v-else-if="getWalletBalance(props.item.id)">
                  <tr v-for="item in getWalletBalance(props.item.id)" v-bind:key="item.coin" class="currency-table">
                    <td class="number-cell pa-0 text-xs-right balance-td" style="width: 100px; overflow-x: hidden">
                      <div style="float: left">
                        <b>{{ getSymbolForCoin(item.coin) }}</b>
                      </div>
                      {{ item.displayValue }}
                    </td>
                    <td class="balance-td" style="width: 10px"></td>
                    <td class="number-cell pa-0 pl-1 pr-1 text-xs-right balance-td" style="width: 80px">
                      <div style="float: left">
                        <b>{{ getSymbolForCurrency(item.fiatValue.currency) }}</b>
                      </div>
                      {{ item.fiatValue.displayValue }}
                    </td>
                  </tr>
                  <tr style="height: 12px">
                    <td class="pa-0 balance-td" style="width: 100px"></td>
                    <td class="balance-td" style="width: 10px"></td>
                    <td
                      class="number-cell pa-0 pr-1 pl-1 text-xs-right text-no-wrap balance-td"
                      style="border-top: 1px solid gray; width: 60px"
                    >
                      <div style="float: left">
                        <b>{{ getWalletTotalFiatCurrency(props.item.id) }}</b>
                      </div>
                      <b>{{ getWalletTotalFiatValue(props.item.id) }}</b>
                    </td>
                  </tr>
                </table>

                <div class="text-xs-center" v-else>
                  <i>Balance Unavailable</i>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <ui-modal v-if="viewDialog" @close="closeDetails">
      <div style="width: 500px">
        <view-wallet :wallet="viewWallet" :close="closeDetails" />
      </div>
    </ui-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

import DefiPositionPreview from '@/components/defi/DefiPositionPreview.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';

import UiModal from '../components/ui/UiModal.vue';
import Beta from '../components/util/Beta.vue';
import DefiWallet from '../components/wallets/DefiWallet';
import ViewWallet from '../components/wallets/ViewWallet';
import { MUT_SNACKBAR } from '../store';
import { getSymbolForCoin, getSymbolForCurrency } from '../utils/coinUtils';

export default {
  props: ['cardStyle', 'short'],
  data() {
    return {
      pagination: {
        sortBy: 'name',
        rowsPerPage: 10,
      },
      btc_rate: 0,
      wallets: [],
      orgs: [],
      isLoading: 0,
      viewWallet: null,
      dialog: false,
      walletHeaders: [
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'name',
          width: '350px',
        },
      ],
      verboseWalletHeaders: [
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'name',
          width: '350px',
        },
        {
          text: 'Vault Address',
          align: 'left',
          value: 'vaultAddress',
          width: '350px',
        },
        {
          text: 'Beneficiary Address',
          align: 'left',
          value: 'ownerAddress',
          width: '350px',
        },
        {
          text: 'Balance',
          align: 'left',
          value: 'balance',
        },
      ],
      isLoadingBalance: 0,
      walletSearchTokens: null,
      defiDetails: {
        isValid: false,
      },
      selectedTab: 0,
      walletPositionsPage: 1,
      itemsPerPage: 6,
      bumpKey: 0,
      viewDialog: false,
      searchKeyword: '',
    };
  },
  mounted() {
    this.refresh();
    this.$root.$on('refresh-wallets', () => {
      this.refresh();
    });
  },
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            description
            type
            deviceType
            address
            addresses
            path
            enabledCoins
            groupId
            bulkSend {
              enabled
              canEnable
            }
            isSyncEnabled
            categorizeTransactions
            accrualCadence
            syncToLatestInteraction
            generateAccruals
            categorizeAccruals
            defaultAccrualContact
            defaultAccrualCategory
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
    walletSearchTokens: {
      query: gql`
        query walletDeFiSearchTokens($orgId: ID!, $walletId: ID!) {
          walletDeFiSearchTokens(orgId: $orgId, walletId: $walletId) {
            walletId
            timestampSeconds
            searchTokens {
              addressDetails_type
              address
              addressDetails_platformId
              addressDetails_contractSymbol
              addressDetails_networkId
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          walletId: this.viewWallet ? this.viewWallet.id : '',
        };
      },
      update: (data) => data.walletDeFiSearchTokens,
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
    walletBalances: {
      query: gql`
        query GetWalletBalances($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            balance {
              balances {
                coin
                value
                displayValue
                fiatValue {
                  value
                  displayValue
                  currency
                }
              }
              totalFiatValue {
                displayValue
                currency
              }
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          walletId: this.viewWallet ? this.viewWallet.id : '',
        };
      },
      update: (data) => data.wallets,
      loadingKey: 'isLoadingBalance',
      errorPolicy: 'ignore',
    },
  },
  computed: {
    displayWallets() {
      return this.wallets.filter((value) => {
        if (value.address) {
          if (value.type !== 22) {
            return true;
          } else {
            return false;
          }
        } else if (value.addresses && value.addresses.length > 0) {
          return true;
        } else {
          return false;
        }
      });
    },
    existingDeFiWallets() {
      return this.wallets.filter((value) => {
        if (value.address) {
          if (value.type === 22) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    },
    filteredPositions() {
      const selectedPositions = this.walletSearchTokens?.searchTokens ?? [];
      let ret;
      if (this.searchKeyword === '') {
        ret = selectedPositions;
      } else {
        ret = selectedPositions.filter((value) => {
          const addressIncludes = (value.address ?? '').toLowerCase().includes(this.searchKeyword.toLowerCase());
          const platformIdIncludes = (value.addressDetails_platformId ?? '')
            .toLowerCase()
            .includes(this.searchKeyword.toLowerCase());
          const contractSymbolIncludes = (value.addressDetails_contractSymbol ?? '')
            .toLowerCase()
            .includes(this.searchKeyword.toLowerCase());
          const networkIdIncludes = (value.addressDetails_networkId ?? '')
            .toLowerCase()
            .includes(this.searchKeyword.toLowerCase());
          const typeIncludes = (value.addressDetails_type ?? '')
            .toLowerCase()
            .includes(this.searchKeyword.toLowerCase());
          return addressIncludes || platformIdIncludes || contractSymbolIncludes || networkIdIncludes || typeIncludes;
        });
      }
      if (ret.length < (this.walletPositionsPage - 1) * this.itemsPerPage) {
        // eslint-disable-next-line
        this.walletPositionsPage = 1;
      }
      return ret;
    },
    displayPositions() {
      const start = (this.walletPositionsPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPositions.slice(start, end);
    },
    walletPositionPageCount() {
      return Math.ceil(this.filteredPositions.length / this.itemsPerPage);
    },
    viewWalletAddress() {
      if (this.viewWallet.address) {
        return this.viewWallet.address;
      } else if (this.viewWallet.addresses && this.viewWallet.addresses.length > 0) {
        return this.viewWallet.addresses[0];
      } else {
        return '';
      }
    },
  },
  watch: {
    '$store.state.isUpdateWallet': function () {
      this.refresh();
    },
    '$store.state.currentOrg.id': function () {
      this.viewWallet = null;
      this.refresh();
    },
  },
  methods: {
    getTabClass(view) {
      if (view === this.selectedTab) {
        return 'tw-bg-neutral-500 tw-text-neutral-100 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
      } else {
        return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    getSymbolForCoin(coin) {
      return getSymbolForCoin(coin);
    },
    getSymbolForCurrency(coin) {
      return getSymbolForCurrency(coin);
    },
    getWalletBalance(id) {
      const wallet = this.walletBalances.find((wallet) => wallet.id === id);
      if (!wallet) {
        return;
      }
      return wallet.balance?.balances;
    },
    getWalletTotalFiat(id) {
      const wallet = this.walletBalances.find((wallet) => wallet.id === id);
      if (!wallet) {
        return;
      }
      return wallet.balance?.totalFiatValue;
    },
    getWalletTotalFiatCurrency(id) {
      return this.getWalletTotalFiat(id)?.currency;
    },
    getWalletTotalFiatValue(id) {
      return this.getWalletTotalFiat(id)?.displayValue;
    },
    walletSaved() {
      this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
      this.hideDialog();
    },
    hideDialog() {
      this.dialog = false;
    },
    closeDetails() {
      this.viewDialog = false;
      this.viewWallet = null;
    },
    showWalletDetails(wallet) {
      this.viewDialog = true;
      this.viewWallet = wallet;
    },
    refresh() {
      this.$apollo.queries.wallets.refetch();
    },
    setActiveWallet(wallet) {
      this.viewWallet = wallet;
      this.$apollo.queries.walletSearchTokens.refetch();
      this.walletPositionsPage = 1;
      this.bumpKey++;
    },
    getFriendlyDate(date) {
      return new Date(date * 1000).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    },
    getPositionIdentifier(networkId, walletAddress, contractAddress, tokenId) {
      if (tokenId) {
        return `${networkId}.${contractAddress}.${walletAddress}.${tokenId}`.toLowerCase();
      } else {
        return `${networkId}.${contractAddress}.${walletAddress}`.toLowerCase();
      }
    },
    createDefiWalletModal(networkId, walletAddress, vaultAddress, tokenId) {
      this.defiDetails = {
        isValid: true,
        networkId,
        walletAddress,
        vaultAddress,
        tokenId,
      };
      this.bumpKey++;
      this.dialog = true;
    },
    defiWalletExists(address) {
      const includesAddress =
        this.wallets.findIndex((wallet) => {
          return wallet.id.toLowerCase() === address;
        }) !== -1;
      return includesAddress;
    },
    defiWalletName(address) {
      const wallet = this.wallets.find((wallet) => {
        return wallet.id.toLowerCase() === address;
      });
      return wallet ? wallet.name : '';
    },
    defiWallet(address) {
      const wallet = this.wallets.find((wallet) => {
        return wallet.id.toLowerCase() === address;
      });
      return wallet;
    },
    async updateDeFiWallet() {
      this.isLoading = true;
      const walletId = this.viewWallet.id;
      const date = new Date(Date.now() - 25 * 60 * 60 * 1000).toISOString().split('T')[0];
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        reportDetails: {
          title: 'Balance Report as of EOD ' + date,
          saveReport: false,
          walletId,
          balanceReport: {
            balanceOnDate: date,
            currency: this.$store.state.currentOrg.baseCurrency,
            includeIgnored: false,
            overrideExchangeRates: undefined,
            deFiTreatment: 'advanced',
            emailReport: '',
            reCheckDeFi: false,
            excludeNft: false,
            skipPricing: true,
            skipPricingSpam: false,
            exportTokenAddresses: false,
            skipBalance: true,
            groupBy: 'wallet',
            priority: 0,
          },
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $reportDetails: ReportDetailsInput!) {
              runReport(orgId: $orgId, reportDetails: $reportDetails) {
                data
              }
            }
          `,
          variables: vars,
        })
        .then(() => {
          this.$apollo.queries.walletSearchTokens.refetch();
          this.isLoading = false;
        })
        .catch((e) => {
          this.showErrorSnackbar('Problem updating DeFi Wallets, Please try again later.');
          console.log(JSON.stringify(e));
          this.isLoading = false;
        });
    },
    showErrorSnackbar(message) {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: message,
      });
    },
  },
  components: {
    DefiWallet,
    DefiPositionPreview,
    ViewWallet,
    UiModal,
    Beta,
    UiSelect2,
    UiButton,
  },
};
</script>
